<template>
  <Fragment>
    <v-card class="pb-4" :loading="details?.isLoading">
      <v-card-title class="font-weight-bold mb-2">
        <NavButton class="mb-2 mb-sm-0" />
        <h2  class="text-uppercase mb-0 ml-2" style="word-break: break-word; font-size: 24px">Detalles</h2>
      </v-card-title>
      <v-card-text style="color: var(--v-primary-darken4);">
        <p class="my-0">
          <b>Estado: </b>
        </p>
        <v-chip 
          class="mt-1 px-4 py-2 text-center" 
          style="height: auto !important; white-space: break-spaces; font-size: 14px !important;"
        >
          {{ tablesData[0]?.proSolCom_bitProSol[0].descripcion }}
        </v-chip>
        <template v-if="tablesData[0]?.justificacion">
          <p class="my-0 mt-6"><b>Justificación: </b></p>
          <p class="my-0">{{ tablesData[0]?.justificacion }}</p>
        </template>

        
        <h3 class="mt-10 mb-2">Administrador de contratos</h3>
        
        <div class="d-flex justify-space-between align-center">
          <p class="mb-0">
            {{ formatearPersona(tablesData[0]?.CMSolicitudCompra?.us_c?.Empleado?.Persona) }}
            <b class="ml-2">({{ tablesData[0]?.CMSolicitudCompra?.us_c?.email }})</b>
          </p>

          <v-btn v-if="tablesData[0]?.CMSolicitudCompra?.cmau[0]?.ruta_documento" small class="ml-1" color="primary" @click.stop="loadAttachment(tablesData[0]?.CMSolicitudCompra?.cmau[0]?.id)">
            <v-icon class="mr-1" color="#FFFFFF !important">mdi mdi-attachment</v-icon>
            Ver orden de compra
          </v-btn>
        </div>


        <h3 class="mt-8 mb-2">Solicitud</h3>
        <v-data-table
            hide-default-footer
            :headers="requestHeaderHeaders"
            :loading="details.isLoading"
            :items="tablesData"
        >
          <template v-slot:item.CMSolicitudCompra.created_at="{ item }">
            {{ formatDate(item?.CMSolicitudCompra?.created_at) }}
          </template>
          <template v-slot:item.CMSolicitudCompra.solicitud_de_emergencia="{ item }">
            {{ item.CMSolicitudCompra?.solicitud_de_emergencia ? 'Si' : 'No' }}
          </template>
          <template v-slot:item.direccion_solicitud="{ item }">
            {{ item.direccion ?? '-' }}
          </template>
        </v-data-table>

        <h3 class="mt-10 mb-2">Oferta</h3>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :loading="details?.isLoading"
            :items="tablesData"
        >
          <template v-slot:item.atributo="{ item }">
            <div>
              <v-chip v-for="(attribute, index) in item.CMOfertaProductoConvenio?.ofeProCon_atrOfe" class="ma-2" :key="index">
                {{ attribute?.nombre }} {{ attribute?.valor }} {{ attribute?.unidad }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.precio_de_oferta="{ item }">
            ${{ item?.precio_de_oferta }}
          </template>
          <template v-slot:item.adicional="{ item }" v-if="validacionCamposAdicionales">
            {{ validacionMonto ?? item.monto ? `Monto: ${item.monto}` : null}}
            {{ item.altura ? `Altura: ${item.altura}` : null}}
            {{ item.base ? `Base: ${item.base}` : null}}
          </template>
          <template v-slot:item.subtotal="{ item }">
            ${{ item?.subtotal }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="request-item-approval">
        <v-row class="px-3" v-if="permisoLectura()">
          <v-col v-for="(step,  index) in buttons" class="px-1 py-1" cols="12" sm="6" :key="index">
            <v-btn style="width: 100%" color="primary" class="ml-0" @click.stop="handleStep(step)">
              {{ step.accion_requerida }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmationModalComponent
        :isOpen="confirmationModal.isOpen"
        :description="confirmationModal.description"
        @confirm="handleConfirmation"
        @cancel="clearConfirmation"
        :isLoading="operation.isLoading"
    />
    <v-dialog v-model="isFormModalOpen" max-width="600px">
      <component
          v-if="activeStep"
          :is="`${activeStep.formulario}-form`"
          @on-save="openConfirm"
          @on-cancel="isFormModalOpen = false"
      />
    </v-dialog>

    <PdfModal
      :isOpen="isPdfModalOpen" 
      :source-loadable="pdfLoadable" 
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />
  </Fragment>
</template>
<script>
import { 
  AdjuntoForm, 
  CantidadAdjuntoForm, 
  CantidadForm, 
  ComentarioForm, 
  FechaForm, 
  ComentarioAdjuntoForm, 
  FechaAdjuntoForm 
} from '@/components/CMForms';
import PdfModal from '@/components/PdfModal.vue';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent, NavButton } from '@/components/utils';
import { convertToFormData } from '@/utils/data';
import { joinList } from '@/utils/text'; 
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';
import { mapMutations } from 'vuex';
import { removePropsFromObjectIfNil, getFilenameFromPath } from '@/utils/data';

const initialConfirmationModal = {
  isOpen: false,
  description: '',
  data: null,
};

export default {
  name: 'RequestItemApproval',
  components: {
    Fragment,
    ConfirmationModalComponent,
    AdjuntoForm,
    CantidadAdjuntoForm,
    CantidadForm,
    ComentarioForm,
    FechaForm,
    ComentarioAdjuntoForm,
    FechaAdjuntoForm,
    NavButton,
    PdfModal
},
  emits: ['on-status-change'],
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    details: {type: Object, required: true},
    buttons: {type: Array, required: true},
    permisoLectura : { type: Function }
  },
  data: () => ({
    // headers
    requestHeaderHeaders: [
      {align: 'center', sortable: false, value: 'CMSolicitudCompra.Institucion.nombre', text: 'Institución'},
      {align: 'center', sortable: false, value: 'CMSolicitudCompra.created_at', text: 'Fecha de creación'},
      {align: 'center', sortable: false, value: 'CMSolicitudCompra.descripcion', text: 'Descripción'},
      {align: 'center', sortable: false, value: 'direccion_solicitud', text: 'Dirección'},
      {align: 'center', sortable: false, value: 'CMSolicitudCompra.telefono', text: 'Teléfono'},
      {
        align: 'center',
        sortable: false,
        value: 'CMSolicitudCompra.solicitud_de_emergencia',
        text: 'Solicitud de emergencia'
      },
    ],
    // UI
    confirmationModal: initialConfirmationModal,
    activeStep: null,
    isFormModalOpen: false,
    // Data
    operation: createLoadable(null),
    steps: [],
    // PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: '',
    activePath: '',
    modalCreacionAdjunto: false,
  }),
  methods: {
    ...mapMutations("catalogoEnLinea", ["setFeedbackModalConfig"]),
    // UI
    formatearPersona(persona) {
      if (!persona) return '';

      const componentes = [
        persona.primer_nombre,
        persona.segundo_nombre,
        persona.tercer_nombre,
        persona.primer_apellido,
        persona.segundo_apellido,
        persona.apellido_casada,
      ]

      return joinList(componentes, ' ');
    },
    handleStep(step) {
      this.activeStep = step;

      if (!step.formulario) return this.openConfirm(null);

      this.isFormModalOpen = true;
    },
    openConfirm(data) {
      this.isFormModalOpen = false;
      this.confirmationModal = {
        isOpen: true,
        description: `¿Desea ${this.activeStep.accion_requerida} esta solicitud?`,
        data: removePropsFromObjectIfNil(data, ['comentario', 'adjunto']),
      };
    },
    formatDate(date) {
      return formatDate(date);
    },
    clearConfirmation() {
      this.confirmationModal = initialConfirmationModal;
    },
    async handleConfirmation() {
      toggleLoadable(this.operation);
      const formData = convertToFormData({
        ...this.confirmationModal.data,
        paso: this.activeStep.paso
      });
      
      const { data } = await this.services.cmSupplierApproval.approve(this.itemId, formData);
      setLoadableResponse(this.operation, data);
      this.confirmationModal = initialConfirmationModal;

      if (!isResponseSuccesful(data)) return
      
      this.setFeedbackModalConfig({
        isOpen: true,
        type: "success",
        description: "Acción realizada con éxito",
        buttonText: "Aceptar",
        onClose: () => (
            this.haveRole('ROLE_CM_PROVEEDOR_ACEPTAR') ?  this.$router.push({ path: `/detalle-orden-compra/${this.$props.details.data[0].id_orden_compra}` }): this.$emit('on-status-change')
        ),
      });
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    async loadAttachment(id) {
      this.isPdfModalOpen = true;
      // if (this.activePath === path) return;

      // this.filename = getFilenameFromPath(path);
      // this.activePath = path;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.cmAprobacionSolicitudCompra.previewAuthFile(id);

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
  },
  computed: {
    tablesData() {
      return this.$props.details.data ?? [];
    },
    validacionMonto(){
      return this.tablesData[0]?.CMOfertaProductoConvenio?.CMProducto.CmSubGrupo.monto
    },
    validacionCamposAdicionales() {
      const subGrupo = this.tablesData[0]?.CMOfertaProductoConvenio?.CMProducto.CmSubGrupo;
      if (!subGrupo) return false;
      const manejoAltura = subGrupo.altura || subGrupo.altura_decimal
      const manejoBase = subGrupo.base || subGrupo.base_decimal
      return subGrupo.monto || manejoAltura || manejoBase    
    },
    headers() {
      const requestDetailsHeaders = [
        { align: 'center', sortable: false, value: 'CMOfertaProductoConvenio.CMProducto.nombre', text: 'Nombre' },
        { align: 'center', sortable: false, value: 'atributo', text: 'Atributos' },
        ...(!this.validacionMonto ? [{ align: 'center', sortable: false, text: 'Precio', value: 'precio_de_oferta' }] : []),
        ...(this.validacionCamposAdicionales ? [{ align: 'center', sortable: false, text: 'Información adicional', value: 'adicional' }] : []),
        { align: 'center', sortable: false, value: 'cantidad', text: 'Cantidad' },
        { align: 'center', sortable: false, value: 'subtotal', text: 'Sub total' },
      ];

      return requestDetailsHeaders;
    },
  },
  created() {}
}
</script>
